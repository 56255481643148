import React, { useEffect, useState } from 'react';

import { styled, theme } from '../Theme';
import Divider from '../Shared/Divider/Divider';
import { mediaQuery } from '../Theme/mediaQueries';
import { media, pseudo } from '@glitz/core';
import VariationModel from '../KexVariation/Models/VariationModel.interface';
import IdCardIcon from '../Shared/Icons/IdCardIcon';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { GetMyInformation } from './GetCustomerInformation';
import CustomerInformationModel from '../MyPagesPage/Models/CustomerInformationModel.interface';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import PageModelBase from '../Shared/Models/PageModelBase.interface';
import { useUserStateData } from '../Shared/UserContextProvider/UserContextProvider';
import CustomerInformationLinks from './CustomerInformation.interface';

type PropTypes = {
  onCheckoutPage?: boolean;
  customerInformationData?: CustomerInformationModel;
  //initData: CustomerInformationLinks;
};

function CustomerInformation({
  onCheckoutPage = false,
  customerInformationData,
}: PropTypes) {
  const {
    translations: {
      'customerInfo/city': cityLabel,
      'customerInfo/company': companyLabel,
      'customerInfo/customerId': customerIdLabel,
      'customerInfo/deliveryAddress': deliveryAddressLabel,
      'customerInfo/zipCode': zipCodeLabel,
      'customerInfo/myDetails': myDetailLabel,
      'customerInfo/customerContact': customerContact,
      'customerInfo/customerContactPhone': customerContactPhone,
      'customerInfo/customerContactEmail': customerContactEmail,
      'checkoutPage/customerDetails': customerDetailsLabel,
      'customerInfo/email': emailLabel,
      'customerInfo/phone': phoneLabel,
    },
    languageRoute,
    requestToken,
  } = useAppSettingsData();
  const { customerId } = useUserStateData();

  const { channelId } = useCurrentPage<PageModelBase>();
  const url = `/api/${languageRoute}/customer/GetMyInformation?customerId=${customerId}`;
  const [customerInformation, setCustomerInformation] = useState<
    CustomerInformationModel
  >();

  const emailLink = 'mailto:' + customerContactEmail;

  useEffect(() => {
    customerId &&
      !onCheckoutPage &&
      GetMyInformation(channelId, requestToken, url).then(
        data => data && setCustomerInformation(data)
      );
  }, [channelId]);

  useEffect(() => {
    customerInformationData && setCustomerInformation(customerInformationData);
  }, [customerInformationData]);

  return (
    <>
      <CustomerInformationComponent data-oncheckout={onCheckoutPage}>
        <HeadingSection>
          <Heading>
            <StyledIdCardIcon />
            {!onCheckoutPage ? myDetailLabel : customerDetailsLabel}
          </Heading>
        </HeadingSection>
        <ContentWrapper data-oncheckout={onCheckoutPage}>
          <Item>
            <ListItemTitle>{companyLabel}</ListItemTitle>
            <ListItem>{customerInformation?.companyName}</ListItem>
          </Item>
          <StyledDivider />

          <Item>
            <ListItemTitle>{customerIdLabel}</ListItemTitle>
            <ListItem>{customerId}</ListItem>
          </Item>
          <StyledDivider />

          <Item>
            <ListItemTitle>{deliveryAddressLabel}</ListItemTitle>
            <ListItem>{customerInformation?.deliveryAddress?.street}</ListItem>
          </Item>
          <StyledDivider />

          <Item>
            <ListItemTitle>{zipCodeLabel}</ListItemTitle>
            <ListItem>
              {customerInformation?.deliveryAddress?.postalCode}
            </ListItem>
          </Item>
          <StyledDivider />

          <Item>
            <ListItemTitle>{cityLabel}</ListItemTitle>
            <ListItem>{customerInformation?.deliveryAddress?.city}</ListItem>
          </Item>
          <StyledDivider />

          <Item>
            <ListItemTitle>{phoneLabel}</ListItemTitle>
            <ListItem>{customerInformation?.companyTelephone}</ListItem>
          </Item>
          <StyledDivider />

          <Item>
            <ListItemTitle>{emailLabel}</ListItemTitle>
            <ListItem>{customerInformation?.companyEmail}</ListItem>
          </Item>
          <StyledDivider />

          <InformationString>{customerContact}</InformationString>
          <InformationStringLink>{customerContactPhone}</InformationStringLink>

          <InformationStringLink
            onClick={e => {
              window.location.href = emailLink;
              e.preventDefault();
            }}
          >
            {customerContactEmail}
          </InformationStringLink>
        </ContentWrapper>
      </CustomerInformationComponent>
    </>
  );
}

const CustomerInformationComponent = styled.div({
  padding: { x: theme.spacing(4), y: theme.spacing(6) },
  width: '45%',
  backgroundColor: theme.white,
  borderRadius: '10px',
  ...pseudo([':nth-child(n)[data-oncheckout="true"]'], {
    width: '100%',
    marginBottom: theme.spacing(9),
  }),
  ...media(mediaQuery.mediaMaxLarge, {
    width: '100%',
    marginBottom: theme.spacing(9),
  }),
  ...media(mediaQuery.mediaMinLarge, {
    padding: {
      left: theme.spacing(8),
      right: theme.spacing(11),
      top: theme.spacing(8),
      bottom: theme.spacing(15),
    },
  }),
});

const ContentWrapper = styled.div({
  ...pseudo([':nth-child(n)[data-oncheckout="true"]'], {
    maxWidth: '500px',
    margin: { x: 'auto' },
  }),
});

const HeadingSection = styled.div({
  ...media(mediaQuery.mediaMaxLarge, { paddingLeft: theme.spacing(1) }),
});

const Heading = styled.h2({
  display: 'flex',
  alignItems: 'center',
  fontSize: theme.zeta,
  margin: { bottom: theme.spacing(11) },
  color: theme.black,
  ...media(mediaQuery.mediaMaxVerySmall, {
    fontSize: theme.larger,
    margin: { bottom: '50px' },
  }),
});

const StyledIdCardIcon = styled(IdCardIcon, {
  marginRight: theme.spacing(4),
  ...media(mediaQuery.mediaMinLarge, {
    marginRight: theme.spacing(5),
  }),
});

const Item = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  ...media(mediaQuery.mediaMaxVerySmall, {
    padding: { x: theme.spacing(4) },
  }),
});

const ListItemTitle = styled.p({
  textTransform: 'uppercase',
  font: { size: theme.headerMainLink, weight: theme.fontWeight.bold },
  color: theme.middleGray,
  ...media(mediaQuery.mediaMinLarge, {
    fontWeight: theme.fontWeight.normal,
  }),
});

const ListItem = styled.p({
  fontSize: theme.beta,
  color: theme.black,
});

const StyledDivider = styled(Divider, {
  width: '100%',
  backgroundColor: theme.grayLine,
  margin: { y: theme.delta },
  ...media(mediaQuery.mediaMaxSmall, {
    margin: { y: theme.delta },
  }),
});

const InformationString = styled.p({
  color: theme.black,
  fontSize: theme.gamma,
  lineHeight: '1.6',
});

const InformationStringLink = styled.a({
  color: theme.linkColor,
  cursor: 'pointer',
  textDecoration: 'underline',
  fontSize: theme.gamma,
  lineHeight: '1.6',
  marginRight: theme.medium,
});

export default CustomerInformation;
