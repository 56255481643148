import React, { useState, useRef } from 'react';

import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { styled, theme } from '../Theme';
import IHeaderLink from '../Shared/Models/Headers/HeaderLink.interface';
import { pseudo } from '@glitz/core';
import KexLink from '../Shared/KexLink/KexLink';
import PlusToggleIcon from '../Shared/Icons/PlusToggleIcon';
import IHeaderLinkModel from '../Shared/AutoMapInterfaces/IHeaderLinkModel.interface';
import FilterItem from '../Shared/FilterItem/FilterItem';
import KexContentLinkModel from '../Search/Models/KexContentLinkModel.interface';

type PropType = {
  pageId: string;
  commerceLinks: IHeaderLinkModel[];
  filters?: KexContentLinkModel[];
  onFilterClick?: any;
  selectedFilters?: any;
};

function FilterContainerDesktop({
  pageId,
  commerceLinks,
  filters,
  onFilterClick,
  selectedFilters,
}: PropType) {
  const {
    translations: {
      'common/sector': sectorLabel,
      'common/showMore': showMoreLabel,
      'common/showLess': showLessLabel,
    },
  } = useAppSettingsData();

  const [showMoreCategories, setShowMoreCategories] = useState(false);
  const [showMoreFilters, setShowMoreFilters] = useState(false);

  const listRef = useRef<HTMLDivElement>(null);
  const filterListRef = useRef<HTMLDivElement>(null);

  const onLinkClick = () => {
    setShowMoreCategories(false);
    listRef.current?.scrollTo(0, 0);
    setShowMoreFilters(false);
    filterListRef.current?.scrollTo(0, 0);
  };

  return (
    <FilterContainer>
      {filters &&
        !!filters.length &&
        filters.map(filter => (
          <CategoryContainer key={filter.name}>
            <Title>{filter.name}</Title>
            <ListContainer ref={filterListRef} data-isopen={showMoreFilters}>
              <>
                {filter.links.map(filterLink => (
                  <FilterItem
                    key={filterLink.name}
                    filter={filterLink}
                    filterType={filter.attributes.value}
                    onFilterChange={onFilterClick}
                    selected={selectedFilters.includes(
                      `&${filter.attributes.value}=${filterLink.name}`
                    )}
                  />
                ))}
              </>
            </ListContainer>
            {filter && filter.links.length > 4 && (
              <ExpandButton
                onClick={() => setShowMoreFilters(!showMoreFilters)}
              >
                <PlusIconWrapper data-isexpanded={showMoreFilters}>
                  <StyledPlusIcon />
                </PlusIconWrapper>
                <ShowMoreText>
                  {!showMoreFilters ? showMoreLabel : showLessLabel}
                </ShowMoreText>
              </ExpandButton>
            )}
          </CategoryContainer>
        ))}
      <CategoryContainer>
        <Title>{sectorLabel}</Title>
        <ListContainer ref={listRef} data-isopen={showMoreCategories}>
          {commerceLinks &&
            !!commerceLinks.length &&
            commerceLinks.map((link: IHeaderLink) => (
              <CategoryLink
                key={link.id}
                data-isactive={link.id === pageId}
                href={link.href}
                onClick={() => onLinkClick()}
              >
                {link.text}
              </CategoryLink>
            ))}
        </ListContainer>
        {commerceLinks && commerceLinks.length > 4 && (
          <ExpandButton
            onClick={() => setShowMoreCategories(!showMoreCategories)}
          >
            <PlusIconWrapper data-isexpanded={showMoreCategories}>
              <StyledPlusIcon />
            </PlusIconWrapper>
            <ShowMoreText>
              {!showMoreCategories ? showMoreLabel : showLessLabel}
            </ShowMoreText>
          </ExpandButton>
        )}
      </CategoryContainer>
    </FilterContainer>
  );
}

const FilterContainer = styled.div({
  font: { size: theme.gamma, weight: theme.fontWeight.bold },
  color: theme.black,
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '280px',
  flex: { grow: 0, shrink: 0, basis: '280px' },
  textTransform: 'uppercase',
  marginRight: theme.spacing(16),
  marginTop: theme.spacing(4),
});

const ShowMoreText = styled.span({
  marginLeft: theme.spacing(2),
});

const ListContainer = styled.div({
  maxHeight: '250px',
  overflow: 'hidden',
  transition: {
    property: 'all',
    duration: '0.2s',
    timingFunction: 'linear',
  },
  ...pseudo([':nth-child(n)[data-isopen="true"]'], {
    maxHeight: '400px',
    overflowY: 'scroll',
    ...pseudo('::-webkit-scrollbar', {
      height: '12px',
      width: '8px',
      borderRadius: '100px',
      backgroundColor: theme.white,
      border: {
        y: {
          style: 'solid',
          width: theme.tiny,
          color: theme.veryLightGray,
        },
      },
    }),
    ...pseudo('::-webkit-scrollbar-thumb', {
      width: '60px',
      borderRadius: '100px',
      backgroundColor: theme.primaryBlue,
    }),
  }),
});

const Title = styled.span({
  font: { size: '16px', weight: theme.fontWeight.boldest },
  paddingBottom: theme.spacing(6),
  marginBottom: theme.spacing(1),
  border: {
    bottom: { width: '2px', style: 'solid', color: theme.grayLine },
  },
});

const PlusIconWrapper = styled.div({
  transition: {
    property: 'all',
    duration: '0.2s',
    timingFunction: 'linear',
  },
  ...pseudo([':nth-child(n)[data-isexpanded="true"]'], {
    transform: 'rotate(45deg)',
  }),
});

const StyledPlusIcon = styled(PlusToggleIcon, {
  width: '16px',
  height: '16px',
});

const CategoryContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(11),
});

const ExpandButton = styled.div({
  display: 'flex',
  alignItems: 'center',
  color: theme.black,
  font: { size: '14px', weight: theme.fontWeight.bold },
  margin: { x: '-5px', top: theme.spacing(1) },
  padding: { xy: theme.spacing(5), left: 0 },
  cursor: 'pointer',
  textTransform: 'uppercase',
  ...pseudo([':nth-child(n)[data-showless="true"]'], {
    margin: { x: 0 },
  }),
});

const CategoryLink = styled(KexLink, {
  display: 'flex',
  fontSize: '14px',
  color: theme.black,
  lineHeight: '16.8px',
  padding: { xy: theme.spacing(4) },
  justifyContent: 'space-between',
  border: {
    bottom: {
      width: '1px',
      style: 'solid',
      color: theme.veryLightGray,
    },
  },
  ...pseudo([':nth-child(n)[data-isactive="true"]'], {
    borderRadius: '10px',
    backgroundColor: theme.lightBlue,
  }),
});

export default FilterContainerDesktop;
